import '/src/css/tailwind.pcss';
import '/src/css/app.pcss';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

function addMultipleEventListener(element, events, handler) {
  events.forEach(e => element.addEventListener(e, handler));
}

window.addEventListener('load', () => {
  console.log("Bootstrapping NB SPCA 🐶 ");

  addMultipleEventListener(document, ['keyup'], (event) => {
    if (event.key === 'Escape') {
      menu.setAttribute('aria-expanded', 'false'); 
      wrap.classList.remove('menu-open');
      tl.reverse()
    }
  });

});

const toggleCSSclasses = (el, ...cls) => cls.map(cl => el.classList.toggle(cl));

const searchTrigger = document.querySelector('#desktopSearch button')
const searchField = document.querySelector('#desktopSearch input[type=search]')
const searchToggleClasses = [
  'w-0', 'opacity-0', 'px-0'
];

addMultipleEventListener(searchTrigger, ['click'], (event) => {
  if (searchToggleClasses.filter(value => searchField.classList.contains(value)).length) { // Search is closed
    event.preventDefault();
    toggleCSSclasses(searchField, ...searchToggleClasses);
    searchField.setAttribute('aria-expanded', true);
    if (searchField.hasAttribute('tabindex')) {
      searchField.removeAttribute('tabindex');
    } else {
      searchField.setAttribute('tabindex', '-1');
    }
    searchField.focus();
  } else if (!searchField.value.length) { // Search is open but no value supplied
    event.preventDefault();
    toggleCSSclasses(searchField, ...searchToggleClasses);
    searchField.setAttribute('aria-expanded', false);
    searchField.blur();
  }
})

const body = document.querySelector('body')
const navTrigger = document.querySelector('nav button.hamburger')
const mobileNav = document.querySelector('#mobileNav')
addMultipleEventListener(navTrigger, ['click'], (event) => {
  toggleCSSclasses(navTrigger, ['is-active'])
  toggleCSSclasses(mobileNav, ['is-active'])
  toggleCSSclasses(body, ...['h-screen', 'overflow-hidden'])
})

const desktopSubNavs = document.querySelectorAll('#desktopnav .nav-has-children')
desktopSubNavs.forEach(subnav => {
  addMultipleEventListener(subnav, ['mouseenter', 'focusin'], (event) => {
    event.stopPropagation()
    subnav.classList.add('is-active')
  })
  addMultipleEventListener(subnav, ['mouseleave', 'focusout'], (event) => {
    event.stopPropagation()
    subnav.classList.remove('is-active')
  })
})

const details = document.querySelectorAll('details')
details.forEach(detail => {
  const button = detail.querySelector('button')
  addMultipleEventListener(button, ['click'], (event) => {
    detail.toggleAttribute('open');
  })
})

const lazyImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));
let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
  entries.forEach(function(entry) {
      if (entry.isIntersecting) {
        let lazySource = entry.target.querySelector('source');
        let lazyImage = entry.target.querySelector('img'); 
        lazySource.srcset = lazySource.dataset.srcset;
        lazyImage.srcset = lazyImage.dataset.srcset;
        entry.target.classList.remove("lazy");
        lazyImageObserver.unobserve(entry.target);
      }
  });
});
lazyImages.forEach(function(lazyImage) {
    lazyImageObserver.observe(lazyImage);
});
